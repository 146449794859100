import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Select from "react-select";
import React from "react";
import { useMainGroupStore } from "../../../../../Store/ManageGroupsStore/MainGroupStore";
import { useCallback } from "react";
import MainGroupsRepository from "../../../../../Repositories/Groups/MainGroupsRepository";
import { toastError, toastSuccess } from "../../../../../Helpers/toasts";
import { loadItems, openFilterList } from "../../actions";
import { useContext } from "react";
import { MainGroupsContext } from "../../../../../Contexts/GroupsContext/MainGroupsContext";
import SaveButton from "../SaveButton";
import { useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const container = {
  width: "100%",
  padding: "0px 25px",
  marginTop: "15px",
  display: "flex",
  justifyContent: "space-between",
};
const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "40px",
    minHeight: "40px",
    width: "100%",
    fontSize: "15px",
    margin: "5px 0",
  }),
};

function SelectMonthTeacher({ selectMonths, monthsOptions }) {
  const { updateTeacherData, subGroupsTeacher, monthsUpdate, isLoadingSave } =
    useMainGroupStore();
  const { mainGroups, dispatchMainGroups } = useContext(MainGroupsContext);
  const [checkBox, setCheckBox] = useState(false);
  const { t } = useTranslation();
  const selectedMonth = useMemo(() => {
    return monthsOptions?.find((item) => item.is_selected === true);
  }, [monthsOptions]);

  const handleSelectChange = useCallback((e) => {
    const selectedFalse = selectMonths?.map((element) =>
      element.is_selected === true
        ? { ...element, is_selected: false }
        : element
    );
    const selectedTrue = selectedFalse?.map((element) =>
      element.month == e.value ? { ...element, is_selected: true } : element
    );
    useMainGroupStore.setState({ monthsUpdate: selectedTrue });
  });

  const handleSubmit = async () => {
    const submitData = {
      can_change_stage: updateTeacherData.can_change_stage,
      exam_taken: updateTeacherData.exam_taken,
      group_id: updateTeacherData.group_id,
      name: updateTeacherData.name,
      group_number: updateTeacherData.group_number,
      sub_groups: subGroupsTeacher,
      months: monthsUpdate,
    };
    useMainGroupStore.setState({ isLoadingSave: true });
    const response = await MainGroupsRepository.updateGroup(submitData);
    if (!response.status) {
      toastError(response.nonFieldErrors);
    }
    if (response.status) {
      toastSuccess(t("Guruh ma'lumotlari yangilandi o'tkazildi"));
      await loadItems({}, mainGroups, dispatchMainGroups);
      openFilterList(null, dispatchMainGroups);
    }
    useMainGroupStore.setState({ isLoadingSave: false });
  };
  const handleChange = useCallback(() => {
    setCheckBox(!checkBox);
  });
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "0px", sm: "0px 25px" },
          zIndex: 99,
        }}
      >
        <label className="select_label_group" htmlFor="month-select">
          {t("Yangi oy")}
        </label>
        <Select
          id="month-select"
          // className="basic-single manage_group_select"
          classNamePrefix="select"
          options={monthsOptions}
          defaultValue={selectedMonth}
          styles={customStyles}
          onChange={handleSelectChange}
          isDisabled={!checkBox}
        />
      </Box>
      <Box sx={container}>
        <FormControlLabel
          checked={checkBox}
          control={<Checkbox onClick={handleChange} />}
          label={t("Yangi oydan belgilash")}
        />
        <SaveButton isloading={isLoadingSave} callback={handleSubmit}>
          {t("Saqlash")}
        </SaveButton>
      </Box>
    </>
  );
}
export default React.memo(SelectMonthTeacher);
