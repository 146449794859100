import { useTranslation } from "react-i18next";
import { useDailyReportsStore } from "../../../../Store/ReportsStore/DaylyReportsStore";
import TableHead from "./TableHead";

function Table() {
  const { results } = useDailyReportsStore();
  const { t } = useTranslation();

  return (
    <table border={"1px"} className="reports-table">
      <TableHead />
      <tbody>
        <tr className="reports-table-body">
          <td>1</td>
          <td style={{ textAlign: "start" }}>{t("Badal to'lov")}</td>
          <td>{t("Naqd")}</td>
          <td>{results?.payment_cash}</td>
          <td>{results?.payment_cash_percent} %</td>
        </tr>
        <tr className="reports-table-body">
          <td>2</td>
          <td style={{ textAlign: "start" }}>{t("Badal to'lov")}</td>
          <td>{t("Plastik")}</td>
          <td>{results?.payment_online} </td>
          <td>{results?.payment_online_percent} %</td>
        </tr>
        <tr className="reports-table-body">
          <td>3</td>
          <td style={{ textAlign: "start" }}>{t("Badal to'lov")}</td>
          <td>{t("Terminal")}</td>
          <td>{results?.payment_terminal} </td>
          <td>{results?.payment_terminal_percent} %</td>
        </tr>
        <tr className="reports-table-body">
          <td>4</td>
          <td style={{ textAlign: "start" }}>
            {t("Smart kartadan chegirma olganlar")}
          </td>
          <td>{results?.smart_card_discounts_count}</td>
          <td>{results?.smart_card_discounts_amount} </td>
          <td></td>
        </tr>
        <tr className="reports-table-body">
          <td>5</td>
          <td style={{ textAlign: "start" }}>
            {t("Oy ohridagi imtixondan olgan chegirmalar soni")}{" "}
          </td>
          <td>{results?.exam_winners_discounts_count}</td>
          <td>{results?.exam_winners_discounts_amount} </td>
          <td></td>
        </tr>
        <tr className="reports-table-body">
          <td>6</td>
          <td style={{ textAlign: "start" }}>
            {t("Chegirmalar sababli yo'qotilgan summalar")}{" "}
          </td>
          <td>{results?.discount_lost_money_percent} %</td>
          <td>{results?.discount_lost_money_amount} </td>
          <td></td>
        </tr>
        <tr className="reports-table-body">
          <td></td>
          <td style={{ textAlign: "start" }}>
            {t(
              "Kunlik to'lovlardan jami summasiga nimsbatan o'rtacha to'lovlar"
            )}{" "}
          </td>
          <td colSpan={3}>{results?.average_payment} </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Table;
