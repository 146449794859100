import { Box } from "@mui/material";
import { useMainGroupStore } from "../../../../../Store/ManageGroupsStore/MainGroupStore";
import TextArea from "../TextArea";
import BasicAlerts from "./ExamAlerts";
import ExamSelectButton from "./ExamSelectButton";

const container = {
  padding: { xs: "0px", sm: "0px 25px" },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const exam_line = {
  width: "100%",
  height: "1px",
  backgroundColor: "#888",
  margin: "15px 0",
};

export default function ExamContainer() {
  const { organizeExam } = useMainGroupStore();

  const unscheduled_exam_months = organizeExam?.unscheduled_exam_months?.map(
    (element) => ({
      label: element.verbose_name,
      value: element.id,
    })
  );

  return (
    <Box sx={container}>
      <TextArea property="exam" />
      <ExamSelectButton unscheduled_exam_months={unscheduled_exam_months} />
      <span style={exam_line}></span>
      <BasicAlerts />
    </Box>
  );
}
