import { Box } from "@mui/material";
import React, { useCallback } from "react";
import SaveButton from "../SaveButton";
import Select from "react-select";
import { useMainGroupStore } from "../../../../../Store/ManageGroupsStore/MainGroupStore";
import { toastError, toastSuccess } from "../../../../../Helpers/toasts";
import MainGroupsRepository from "../../../../../Repositories/Groups/MainGroupsRepository";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const select_container = {
  display: "flex",
  flexDirection: "column",
  width: { xs: "100%", sm: "200px" },
  zIndex: 100,
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "45px",
    minHeight: "45px",
    width: "100%",
    fontSize: "15px",
    margin: "5px 0",
  }),
};

const select = {
  width: "100%",
  marginTop: "0px",
  display: "flex",
  flexDirection: { xs: "column", sm: "row" },
  alignItems: "end",
  zIndex: 9,
  justifyContent: "space-between",
};

function ExamSelectButton({ unscheduled_exam_months }) {
  const { examMonthId, examComment, groupID, isLoadingSave } =
    useMainGroupStore();

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      shouldbe_taken: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      shouldbe_taken: Yup.string()
        .min(1, t("Imtixon vaqtini belgilang!"))
        .required(t("Imtixon vaqtini belgilang!")),
    }),
    onSubmit: (values) => {},
  });

  const handleSubmit = async () => {
    formik.handleSubmit();
    if (!formik.errors.shouldbe_taken && formik.values.shouldbe_taken) {
      const submitData = {
        group_id: groupID,
        month_id: examMonthId,
        comment: examComment,
        ...formik.values,
      };
      useMainGroupStore.setState({ isLoadingSave: true });
      const response = await MainGroupsRepository.saveGroupOrganizeExam(
        submitData
      );
      if (!response.status) {
        toastError(response.nonFieldErrors);
      }
      if (response.status) {
        toastSuccess(t("Imtixon so'rovi qabul qilindi"));
      }
      useMainGroupStore.setState({ isLoadingSave: false });
    }
  };

  const handleSelectChange = useCallback((e) => {
    useMainGroupStore.setState({
      examMonthId: e.value,
    });
  });

  return (
    <Box sx={select}>
      <Box sx={select_container}>
        <label className="select_label_group" htmlFor="month-select">
          {t("Ushbu oy")}
        </label>
        <Select
          sx={{ marginottom: "0" }}
          id="month-select"
          className="basic-single manage_group_select"
          classNamePrefix="select"
          styles={customStyles}
          options={unscheduled_exam_months}
          onChange={handleSelectChange}
        />
      </Box>
      <Box
        sx={{
          heigh: "100%",
          width: { xs: "100%", sm: "auto" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <label className="select_label_group" htmlFor="month-select">
          {t("Imtixon olinish sanasi")}
        </label>
        <input
          className={
            formik.touched.shouldbe_taken && formik.errors.shouldbe_taken
              ? "modal-input exam-input-module error_input"
              : "modal-input exam-input-module "
          }
          type="date"
          name="shouldbe_taken"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.shouldbe_taken}
        />
        {formik.touched.shouldbe_taken && formik.errors.shouldbe_taken ? (
          <p className="input-error-message exam-input-error">
            {formik.errors.shouldbe_taken}
          </p>
        ) : null}
      </Box>
      <SaveButton isloading={isLoadingSave} callback={handleSubmit}>
        {t("Saqlash")}
      </SaveButton>
    </Box>
  );
}
export default React.memo(ExamSelectButton);
