import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AdvancegroupNextLevel from "./AdvancegroupNextLevel";
import { useMainGroupStore } from "../../../../../Store/ManageGroupsStore/MainGroupStore";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { sub_groups } = useMainGroupStore();

  return (
    <Box sx={{ width: "100%", padding: 0 }}>
      <Box sx={{ marginBottom: "20px", padding: 0 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          {sub_groups?.map((element, index) => {
            const lessonStyle = element?.lesson_style;
            return (
              <Tab
                key={lessonStyle?.value}
                label={lessonStyle?.verbose_name}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      {sub_groups?.map((element, index) => {
        return (
          <TabPanel
            key={index}
            value={value}
            index={index}
            sx={{ height: "100%", padding: "0 !important" }}
          >
            <AdvancegroupNextLevel element={element} index={index} />
          </TabPanel>
        );
      })}
    </Box>
  );
}
