import { Button, Modal, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import { useState } from "react";
import NextStageModalLoader from "../../../../Components/Loader/basic loader/NextStageModalLoader";
import { MainGroupsContext } from "../../../../Contexts/GroupsContext/MainGroupsContext";
import { useMainGroupStore } from "../../../../Store/ManageGroupsStore/MainGroupStore";
import { openFilterList } from "../actions";
import ExamContainer from "./ExaminationRequest/ExamContainer";
import ModalContainer from "./FinishGroup/ModalContainer";
import BasicTabs from "./TeacherChange/CenteredTabs";
import SelectMonthTeacher from "./TeacherChange/SelectMonth";
import LabTabs from "./NextStageGroup/CenteredTabs";
import SelectMonth from "./NextStageGroup/SelectMonth";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import { useTranslation } from "react-i18next";

const containerStyle = (isSmallScreen) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isSmallScreen ? "100%" : 1000,
  height: isSmallScreen ? "100%" : 550,
  maxHeight: "100%",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: isSmallScreen ? 2 : 4,
  borderRadius: "10px",
  outline: "none",
  flexGrow: 1,
  display: "flex",
  flexDirection: isSmallScreen ? "column" : "row",
});

const loader = {
  position: "relative",
  width: "100%",
  borderRadius: "10px",
  heigh: "100%",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function VerticalTabs() {
  const { mainGroups, dispatchMainGroups } =
    React.useContext(MainGroupsContext);
  const [value, setValue] = useState(0);

  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { data, isLoading } = useMainGroupStore();

  const monthsOptions =
    data?.months?.map((element) => ({
      label: element.name,
      value: element.month,
      is_selected: element.is_selected,
    })) || [];

  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();
  return (
    <Modal
      className="filterModal"
      onClick={(e) => e.stopPropagation()}
      open={mainGroups.isOpenFilterList}
      onClose={() => openFilterList(null, dispatchMainGroups)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={containerStyle(isSmallScreen)}>
        {isLoading ? (
          <Box sx={loader}>
            <NextStageModalLoader />
          </Box>
        ) : (
          <Box sx={containerStyle(isSmallScreen)}>
            <Tabs
              variant="scrollable"
              value={value}
              onChange={handleChange}
              orientation={isSmallScreen ? "horizontal" : "vertical"}
              aria-label="Vertical tabs example"
            >
              <Tab label={t("Keyingi bosqichga o'tkazish")} {...a11yProps(0)} />
              <Tab label={t("To'xtatish / Yakunlash")} {...a11yProps(1)} />
              <Tab label={t("Sozlash")} {...a11yProps(2)} />
              <Tab label={t("Imtixon so'rovi")} {...a11yProps(3)} />
            </Tabs>
            <UserPermissionWidget
              hasPermission={user?.permissions?.move_to_next_stage}
            >
              <TabPanel value={value} index={0} className="tab_panel">
                <LabTabs />
                <SelectMonth
                  selectMonths={data?.months}
                  monthsOptions={monthsOptions}
                />
              </TabPanel>
            </UserPermissionWidget>
            <UserPermissionWidget
              hasPermission={user?.permissions?.close_or_stop_group}
            >
              <TabPanel
                value={value}
                index={1}
                style={{ padding: 0 }}
                className="tab_panel"
              >
                <ModalContainer />
              </TabPanel>
            </UserPermissionWidget>
            <UserPermissionWidget
              hasPermission={user?.permissions?.change_group_settings}
            >
              <TabPanel value={value} index={2} className="tab_panel">
                <BasicTabs />
                <SelectMonthTeacher
                  selectMonths={data?.months}
                  monthsOptions={monthsOptions}
                />
              </TabPanel>
            </UserPermissionWidget>
            <UserPermissionWidget
              hasPermission={user?.permissions?.create_exam_request}
            >
              <TabPanel value={value} index={3} className="tab_panel">
                <ExamContainer />
              </TabPanel>
            </UserPermissionWidget>
            <Button
              onClick={() => openFilterList(null, dispatchMainGroups)}
              variant="outlined"
              sx={{ display: { xs: "block", sm: "none" }, my: 5 }}
            >
              Ortga
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
export default React.memo(VerticalTabs);
