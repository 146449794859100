import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useIncomeCashStore } from "../../../../Store/CashControlStore/IncomeCashStore";

export default function ColorToggleButton() {
  const [alignment, setAlignment] = React.useState("web");
  const { payment_types, updateAcceptPayment, acceptPayment } =
    useIncomeCashStore();

  const handleChange = (event, newAlignment) => {
    updateAcceptPayment("payment_type", newAlignment);
    useIncomeCashStore.setState({
      paymentId: null,
    });
  };

  return (
    <ToggleButtonGroup
      sx={{ width: { xs: "100%", sm: "auto" } }}
      color="primary"
      value={acceptPayment.payment_type}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      size="small"
    >
      {payment_types?.map((item) => {
        return (
          <ToggleButton
            size="small"
            key={item.value}
            value={item.value}
            sx={{
              fontSize: "13px",
              textTransform: "capitalize",
              fontFamily: "Inter",
              fontWeight: 400,
            }}
          >
            {item.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
